import { useState } from 'react';
import { useCookies } from 'react-cookie';

const Auth = () => {
	const [cookies, setCookie, removeCookie] = useCookies(null);
	const [isLogIn, setIsLogIn] = useState(true);
	const [email, setEmail] = useState(null);
	const [password, setPassword] = useState(null);
	const [confirmPassword, setConfirmPassword] = useState(null);
	const [error, setError] = useState(null);

	const viewLogin = (status) => {
		setError(null);
		setIsLogIn(status);
	};

	const handleSubmit = async (e, endpoint) => {
		e.preventDefault();
		//if on a sign up page (not login)
		if (!isLogIn && password !== confirmPassword) {
			setError('Make sure passwords match');
			return;
		}

		const response = await fetch(`/api/${endpoint}`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ email, password }),
		});
		//picking up what server sent to us (res.json())
		const data = await response.json();
		//if error
		if (data.detail) {
			setError(data.detail);
			console.log(data);
		} else {
			//if not error
			setCookie('Email', data.email);
			setCookie('AuthToken', data.token);
			window.location.reload();
		}
	};

	return (
		<div className='auth-container'>
			<div className='auth-container-box'>
				<form>
					<h2>{isLogIn ? 'Please LOG IN' : 'Please SIGN UP'}</h2>
					<input type='email' placeholder='email' onChange={(e) => setEmail(e.target.value)} />
					<input
						type='password'
						placeholder='password'
						onChange={(e) => setPassword(e.target.value)}
					/>
					{!isLogIn && (
						<input
							type='password'
							placeholder='confirm password'
							onChange={(e) => setConfirmPassword(e.target.value)}
						/>
					)}
					<input
						onClick={(e) => handleSubmit(e, isLogIn ? 'login' : 'signup')}
						type='submit'
						className='create'
					/>
					{error && <p>{error}</p>}
				</form>
				<div className='auth-options'>
					<button
						onClick={() => viewLogin(false)}
						style={{ backgroundColor: !isLogIn ? 'rgb(255, 255, 255)' : 'rgb(188, 188, 188)' }}
					>
						Sign Up
					</button>
					<button
						onClick={() => viewLogin(true)}
						style={{ backgroundColor: !isLogIn ? 'rgb(188, 188, 188)' : 'rgb(255, 255, 255)' }}
					>
						Login
					</button>
				</div>
			</div>
		</div>
	);
};
export default Auth;
