import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import Auth from './components/Auth';
import ListHeader from './components/ListHeader';
import ListItem from './components/ListItem';

function App() {
	const [cookies, setCookie, removeCookie] = useCookies(null);
	const userEmail = cookies.Email;
	//authToken dictates if we see
	const authToken = cookies.AuthToken;
	const [tasks, setTasks] = useState(null);

	//when log in get userName and fetch
	const getData = async () => {
		try {
			const response = await fetch(`/api/todos/${userEmail}`);
			const json = await response.json();
			setTasks(json);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (authToken) getData();
	}, [authToken]);

	//sort by date
	const sortedTasks = tasks?.sort((a, b) => new Date(a.date) - new Date(b.date));

	return (
		<div className='app'>
  
			{!authToken && 
      <div>
        <Auth />
        <a download href="/server/public/haha.xlsx">download</a>
      </div>  
        }
			{authToken && (
				<>
					<ListHeader listName={'Holiday Tick list 🎄'} getData={getData} />
					<p className='user-email'>Welcome back {userEmail}</p>
					{/* pass the whole task object into ListItem */}
					{sortedTasks?.map((task) => (
						<ListItem key={task.id} task={task} getData={getData} />
					))}
				</>
			)}
		</div>
	);
}

export default App;
