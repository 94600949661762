const ProgressBar = ({ progress }) => {
	return (
		<div className='outer-bar'>
			<div
				className='inner-bar'
				style={{ width: `${progress}%`, backgroundColor: 'rgb(12, 122, 222)' }}
			></div>
		</div>
	);
};
export default ProgressBar;
